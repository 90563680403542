import React from 'react'

export default function CounterServices() {
    return <>
        <div className='bg-blue'>
            <div className='container pt-3'>
                <div className='row'>
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>+3</h1>
                            <p>British Master Degrees</p>
                        </div>
                    </div>

                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>+10</h1>
                            <p>Professional Stuff</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='text-center mb-3'>
                            <img src='https://eadentalclinic.com/wp-content/uploads/2024/06/survey.png' />
                        </div>
                    </div>
                    
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>%100</h1>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                    
                    <div className='col-lg-2 col-md-6 col-sm-12'>
                        <div className='text-color text-center'>
                            <h1>+5</h1>
                            <p>Years Of Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
