import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import { Helmet } from 'react-helmet';

export default function About() {
    
    const { t } = useTranslation();
    let languag = localStorage.getItem("language")
    const [Date, setDate] = useState([])
    const [loading, setLoading] = useState(true);
    const { url, lan, setLan } = useContext(UserContext);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/home?lang=${lan}`);
                setDate(response?.data?.data?.content || []);
            } catch (error) {
                console.error("Error fetching slider data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);

    return (
        <>
       
            <div className='' id='about'>
                <div className='container pt-5'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className={languag == "ar" ? "text-end" : "text-start"}>
                                {/* <p className='text-color'> {t("welcomedent")} </p> */}
                                <h2 className='text-color mt-1'>{Date[0]?.title}</h2>
                                <p className='text-color mt-4'>
                                    {Date[0]?.description}
                                </p>
                                <a href=
                                    "https://wa.me/01126303082?text=I'd%20like%20to%20make%20an%20appointment"
                                    className="btn btn-color text-color" target="_blank">
                                    <i className="fab fa-whatsapp"></i> {t("appointmentmake")}
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <div className='w-100'>
                                <div className='wp-block-tornado-about-section alignfull about-us' id='AboutUs'>
                                    <div>
                                        <div>
                                            <div>

                                            </div>
                                            <div className='col-lg- col-sm-12'>
                                                <div className='about-video'>
                                                    <video className='w-100' controls>
                                                        {/* <source src='https://eadentalclinic.com/wp-content/uploads/2024/06/about.mp4' type='video/mp4' /> */}
                                                        <source src={Date[0]?.file} type='video/ogg' />

                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
