import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/Context';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material';
import axios from 'axios';

export default function Navbar() {
  const { t } = useTranslation();
  const x = useContext(UserContext);
  const [language, setLanguage] = useState(i18n.language || 'en');
  const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  let lastScrollY = 0;



  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);

  const { url, lan } = useContext(UserContext);

  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await axios.get(`${url}/home?lang=${lan}`);
              setData(response?.data?.data?.setting || []);
              setDate(response?.data?.data)
          } catch (error) {
              console.error("Error fetching slider data", error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
  }, [lan, url]);
console.log(data?.email
);


  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
    document.body.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  const toggleTheme = () => {
    setMymode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      return newMode;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 80) {
        setIsScrolled(true);

        if (currentScrollY > lastScrollY) {
          setIsHidden(true); // إخفاء الـ Navbar عند التمرير للأسفل
        } else {
          setIsHidden(false); // إظهار الـ Navbar عند التمرير للأعلى
        }
      } else {
        setIsScrolled(false); // إعادة إظهار الـ Navbar عند العودة للأعلى
      }

      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const darkTheme = createTheme({
    palette: {
      mode: mymode,
    },
  });

  let languag = localStorage.getItem("language");
console.log(data?.twitter);

  return (
    <div className={`bg-color d-none d-lg-block ${isScrolled ? 'navbar-scrolled' : ''} ${isHidden ? 'hide-navbar' : ''}`}>
    <div className='container'>
      <div className='row'>
        <div className="col-6 top-bar-social">
          <ul className="list-unstyled d-flex pt-1 pb-0 mb-0">
            <li className='mx-1'>
              <a href={data?.twitter} target="_blank" className='text-color'>
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li className='mx-1'>
              <a href={data?.instgram} target="_blank" className='text-color'>
                <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li className='mx-1'>
              <a href={data?.yotube} target="_blank" className='text-color'>
                <i className="fa-brands fa-youtube"></i>
              </a>
            </li>
            <li className='mx-1'>
              <a href={data?.fecabook} target="_blank" className='text-color'>
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li className='mx-1 text-color'>
              <a href={`mailto:${data?.email}`} target="_blank" className='text-decoration-none text-color'>
                {date?.email} <i className="fa-solid fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className={languag === "ar" ? "text-start pt-1" : "text-end pt-1"}>
            <p className="text-color font-12 pt-1 fw-bold">{t("dentalclinic")}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
}
