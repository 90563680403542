import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import { BallTriangle, ThreeCircles } from 'react-loader-spinner';
import i18n from '../../i18n';
import logo from "../../../src/Img/Untitled .png";
import logor from "../../../src/Img/logor.png";
import { useLocation } from 'react-router-dom';

export default function Slider() {
  const { t } = useTranslation();
  const { url, lan, setLan } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const { pathname } = useLocation();

  // Fetch slider data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/home?lang=${lan}`);
        setData(response?.data?.data?.sliders || []);
      } catch (error) {
        console.error("Error fetching slider data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lan, url]);

  // Handle language change
  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    setLan(selectedLanguage); // Update language in context
    localStorage.setItem('language', selectedLanguage);
  };

  // Update i18n language when `lan` changes
  useEffect(() => {
    i18n.changeLanguage(lan);
  }, [lan]);
  // Initialize theme from localStorage or default to 'light'
  const [mymode, setMymode] = useState(() => localStorage.getItem('theme') || 'light');

  return (
    <div className='container-fluid2'>
      {/* Responsive Navbar for Mobile */}


      <div className='d-block d-lg-none position-absolute z-20 position-nav'>
        <div className={` ${isScrolled ? 'navbar-scrolled' : ''}`}>
          <nav className="navbar navbar-expand-lg nav-h-100">
            <div className="container">
              <div className="navbar-brand">
                {/* Language Selector */}
                <select value={lan} onChange={changeLanguage} className='btnNav w-select '>
                  <option value="en">EN</option>
                  <option value="ar">AR</option>
                  <option value="de">DE</option>
                </select>
                {/* Communication Link */}
                <a href='BookAppointment' className='text-decoration-none' target='_blank' rel='noopener noreferrer'>
                  <div className='d-block btnNav mt-1'>
                    <div className='text-white mx-3 pt-1 d-center'> {t("communicaion")}  </div>
                  </div>
                </a>
              </div>
              <form className="d-flex img-nav">
                <a href='/'>
                  <img src={logo} className='w-100 ' alt="Logo" />
                </a>
              </form>
              <button
                className="navbar-toggler text-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fa-solid fa-bars "></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto ">
                  <li className="nav-item mx-1">
                    <a className={pathname == "/" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/">{t("home")}</a>
                  </li>
                  <li className="nav-item mx-1">
                    <a className={pathname == "/about" ? "nav-link fw-bold infonav text-color" : "  nav-link fw-bold text-color"} href="/about">{t("about")}</a>
                  </li>
                  <li className="nav-item mx-1">
                    <a className={pathname == "/AllServices" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/AllServices">  {t("services")}   </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a className="nav-link fw-bold text-color" href="#Doctors">  {t("ourteam")}  </a>
                  </li>

                  <li className="nav-item mx-1">
                    <a className={pathname == "/contactus" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/contactus">    {t("contactus")} </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a className={pathname == "/FAQ" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="/FAQ">  {t("faq")} </a>
                  </li>

                  <li className="nav-item mx-1">
                    <a className={pathname == "/Reviews" ? "nav-link fw-bold infonav text-color" : " nav-link fw-bold text-color"} href="Reviews">   {t("reviews")} </a>
                  </li>
                </ul>
                <form className="d-flex img-nav mb-2">
                  <a href='/'>
                    <img src={logor} className='w-130 ' alt="Logo" />
                  </a>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Carousel Slider */}
      <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {loading ? (
            // Loader while fetching data
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0d2338"
                ariaLabel="ball-triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : data.length > 0 ? (
            // Display carousel items if data is available
            data.map((slider, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                <div className='positionhover position-absolute'>
                  <div className='positionhover'></div>
                </div>
                <img src={slider.image} className="d-block w-100 h-sliderimg" alt={slider.altText || 'Slider image'} />
                <div className={lan === "ar" ? "carousel-caption d-none d-md-block text-en" : "carousel-caption d-none d-md-block text-start"}>
                  <p className='text-color'>{slider.title || 'EA Elegant Art'}</p>
                  <h2 className='text-color '>
                    {slider?.description}
                  </h2>
                  <a href={slider.link || "#"} className="btn btn-color">
                    {t("appointmentmake")}
                  </a>
                </div>
              </div>
            ))
          ) : (
            // Message if no sliders are available
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
              <p>{t("noSlidersAvailable")}</p>
            </div>
          )}
        </div>
        {/* Carousel Controls */}
        {data.length > 0 && (
          <>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{t("previous")}</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{t("next")}</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
