import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Context/Context';
import axios from 'axios';

export default function Services() {
    const { t } = useTranslation();
    let languag = localStorage.getItem("language");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const { url, lan } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/home?lang=${lan}`);
                setData(response?.data?.data?.features || []);
            } catch (error) {
                console.error("Error fetching slider data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [lan, url]);

    return (
        <>
            <div className='bg-color'>
                <div className='container pt-5'>
                    <div className='row'>
                        {/* استخدم map لعرض البيانات بشكل ديناميكي */}
                        {data.map((feature, index) => (
                            <div className='col-lg-3 col-md-6 col-sm-12 mt-3' key={index}>
                                <div className='feat-img mx-auto'>
                                    {/* تأكد من استخدام الصورة من الـ API */}
                                    <img src={feature.file || 'default-image-path.png'} className='p-2 img-fluid' alt={feature.title} />
                                </div>
                                <div className='d-center flex-column'>
                                    <h5 className='text-white mt-3 font-17'>{feature.title}</h5>
                                    <p className='text-color'>{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
